import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import ErrorHandlerService from "@/modules/common/services/error-handler.service";
import Notification from "@/modules/common/services/notification.service";
import GtrStorage from "@/modules/common/services/storage.service";
import { AxiosError } from "axios";
import Container from "typedi";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
    name: 'GtrRegistrationLoginView',
    computed: {
        ...mapState('options', ['participantUpdated']),
        ...mapState('auth', ['needAuthField', 'requireInvite']),
        ...mapState('event', ['event'])
    }
})
export default class GtrRegistrationLoginView extends GtrSuper {
    @Prop()
    allContent: any

    @Prop()
    design: any

    @Prop()
    settings: any

    @Prop()
    option_groups: any

    @Prop()
    tiers: any

    @Prop()
    content_pages: any

    @Prop()
    event_uuid: any

    @Prop()
    template_name: any

    @Prop()
    participant: any

    errorType: string | null = "Invite Required";
    errorModal = false;
    errorModalMessage: string | null = null;

    data() {
        return {
            option_group_uuid: false,
            registration_type: false,
            event_identifier: '',
            login_key: '',
            pagenum: '',
            referralCode: '',
            loginFieldData: {},
            currentLanguage: 'en',
            requestInviteDialog: false,
            RI_first_name: '',
            RI_last_name: '',
            RI_email: '',
            _requireInvite: null,
            _event: null
        }
    }

    //#region Lifecycle

    async mounted() {
        if (this.$route.name === 'registration.login.exhibitor' ) {
            if (localStorage.getItem("idp_login") == null) {
                const payload = {
                    event_identifier: this.$route.params.event_identifier,
                    data: {return_url: window.location.href}
                }
                const result = await this.$store.dispatch('register/idpStart', payload, { root: true })
                this.checkURLAndGo(result.data.redirect_url)
                return
            }
            else {
                const idpLoginData = localStorage.getItem("idp_login")
                if (idpLoginData) {
                    this.checkURLAndGo(JSON.parse(idpLoginData).return_url)
                    return
                }
            }
        }
        this.$data.event_identifier = this.$route.params.event_identifier
        this.$data.pagenum = this.$route.params.pagenum
        this.$data.login_key = this.$route.params.login_key
        this.$data.referralCode = this.$route.query.referralCode
        const $_GET: any = {}
        if (document.location.toString().indexOf('?') !== -1) {
            const query = document.location
                .toString()
                .replace(/^.*?\?/, '')
                .replace(/#.*$/, '')
                .split('&')

            for (let i = 0, l = query.length; i < l; i++) {
                const aux = decodeURIComponent(query[i]).split('=')
                $_GET[aux[0]] = aux[1]
            }
        }
        if ($_GET['language']) {
            this.$data.currentLanguage = $_GET['language']
        }
        const self = this
        if (this.$route.name !== 'registration.login.exhibitor' ) {
            setTimeout(function() {
                self.$store.dispatch('event/sendAnalytics', { event_identifier: self.$data.event_identifier })
            }, 1000)
        }
        // check for referral_code and update referral code click count
        if (this.$data.referralCode) {
          this.addToReferralCodeClickCount()
        }
    }
    //#endregion

    //#region Watchers
    @Watch('requireInvite')
    onRequireInviteChange (payload: any) {
        if (payload) {
            this.$data._requireInvite = payload
        }
    }

    @Watch('event')
    onEventChange (payload: any) {
        if (payload) {
            this.$data._event = payload
        }
    }

    // Flag for deletion. Seemed to be breaking things when first page override was set.
    // @Watch('participantUpdated')
    // onParticipantUpdatedChange (payload: any) {
    //     if (payload) {
    //         this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier + '/register/0')
    //     }
    // }

    @Watch('settings')
    async onSettingsChange (payload: any) {
        if (this.$data.login_key && !payload.editing_disabled) {
            const payload = {
                event_identifier: this.$data.event_identifier,
                isDev: this.isDev,
                data: {
                    login_key: this.$data.login_key,
                    pagenum: this.$data.pagenum,
                }
            }
            try {
                await this.$store.dispatch('auth/login', payload)
            } catch (error) {
                Container.get(ErrorHandlerService).error(error)
            }
        }
    }
    //#endregion

    //#region Computed Props
    get loginFields() {
        return this.settings.login_fields
    }

    get subscription_level() {
        return this.$data._event ? this.$data._event.subscription_level : ''
    }

    get needRegType() {
        return this.settings.require_reg_type_before_registering && !this.$data.registration_type
    }

    get regTypeVerbiage() {
        return this.settings.regtype_selection_verbiage
    }

    get mcProEnabled() {
        return this.settings.mcpro_login_enabled
    }

    get mcTradeEnabled() {
        return this.settings.mctrade_login_enabled
    }

    get regTypeOptions() {
        for (const option_group_index in this.option_groups) {
            const option_group = this.option_groups[option_group_index]
            if (option_group.name == 'Registration Types') {
                return option_group.options
            }
        }
        return null
    }

    get optionGroupUuid() {
        for (const option_group_index in this.option_groups) {
            const option_group = this.option_groups[option_group_index]
            if (option_group.name == 'Registration Types') {
                return option_group.uuid
            }
        }
        return null
    }

    get showTheRequestInviteButton() {
        return this.$data._requireInvite && this.settings.allow_invite_requests
    }

    get isLoginClosed() {
        return this.settings.login_closed
    }

    get isEditingDisabled() {
        return this.settings.editing_disabled
    }

    get login_button_text() {
        return this.design.default_login_button_text && this.design.default_login_button_text[this.$data.currentLanguage]
            ? this.design.default_login_button_text[this.$data.currentLanguage]
            : 'Login'
    }
    //#endregion

    //#region Methods
    async setRegType(registration_type_param: any) {
        try {
            this.$data.registration_type = registration_type_param
            const payload: any = {
                event_identifier: this.event_uuid,
                reg_type: this.$data.registration_type.uuid,
            }
            await this.$store.dispatch('register/getAllContent', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async requestInvite() {
       try {
            this.$data.requestInviteDialog = false
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                data: {
                    first_name: this.$data.RI_first_name,
                    last_name: this.$data.RI_last_name,
                    email: this.$data.RI_email,
                }
            }
            await this.$store.dispatch('auth/requestInvite', payload)
       } catch (error) {
           Container.get(ErrorHandlerService).error(error)
       }
    }

    showErrorModal (message: string, code: string) {
      this.errorModal = true
      this.errorModalMessage = message
      this.errorType = code.split('_').join(' ').toLowerCase()
    }

    private async handleNoRegistrationError (payload: any): Promise<any> {
        try {
          const response = await this.$store.dispatch('auth/registration', payload)
          const access_token = response.data.access_token
          /**
           * Keep the group parent access token for group related calls
           * Use the new access token created here for all other calls
           */
          if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`) && Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`) && !Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_parent_access_token`)) {
              /**
               * First registration's access token should now be the group parent token, to be used in group calls
               */
              Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_group_parent_access_token`,Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`))
          }
          /**
           * After saving the group parent access token or just doing a regular single registration login, set the access token in local storage and for axios
           */
          Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_registration_access_token`, access_token)
          this.$store.commit('auth/SET_VIEWABLE_REG_PAGES', response.data.viewable_reg_pages)
          this.$store.commit('auth/SET_CURRENT_REG_PAGES', response.data.current_reg_pages)
          if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)) {
              const group_uuid = Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)
              const data = {
                  event_identifier: this.$data.event_identifier,
                  group_uuid,
              }
              await this.$store.dispatch('register/addToGroupRegistration', data, { root: true })
          }
          if (payload.data.registrationTypeData) {
              const registrationTypeData = payload.data.registrationTypeData

              const dataOption = {
                  option_group_uuid: registrationTypeData.option_group_uuid,
                  option_uuid: registrationTypeData.option_uuid,
                  event_identifier: this.$data.event_identifier,
                  qty: 1, //default to 1 for now
              }

              await this.$store.dispatch('options/optionChange', dataOption, { root: true })
          }
          this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier +'/register/' + response.data.viewable_reg_pages[0])
      } catch (error) {
          /**
           * If site is required, make the call to request for an invite here
           */
          if (error.response.data.error_code === 'INVITE_REQUIRED') {
            const { error_message: message, error_code: code } = error.response.data;
            this.showErrorModal(message, code)
            this.$store.commit('auth/SET_REQUIRE_INVITE', true)
          } else {
            Container.get(Notification).error(error.response.data.error_message)
          }
      }
    }

    // TODO: this function should be broken into smaller functions.
    // TODO: Tear out login_key functionality and put in a libary. This code is used in the register page too. ver batim.
    async login () {
        // Display the loader
        this.$store.dispatch('common/showLoader', { value: true})
        /**
         * payload
         * @field event_identifier { string }
         * @field pagenum { string }
         * @field isDev { bool }
         * @field data { object }
         */
        const payload: any = {
            event_identifier: this.$data.event_identifier,
            pagenum: this.$data.pagenum,
            isDev: this.isDev,
            data: {
                login_key: this.$data.login_key,
                referral_code: this.$data.referralCode // this will update referral code participant usage count
            }
        }

        // For the fields in the loginFieldData
        for (const field in this.$data.loginFieldData) {
          // when the field is the email.
          if (field === 'email') {
            // inject the email field into the payload data.
            payload.data[field] = this.$data.loginFieldData[field].toLowerCase() // format all email to lowercase
          } else {
            // otherwise inject the field as is to the payload.
            payload.data[field] = this.$data.loginFieldData[field]
          }
        }
        // if registration_type AND registration_type.uuid
        if (this.$data.registration_type && this.$data.registration_type.uuid) {
            // inject the registrationTypeData into the payload.
            payload.data.registrationTypeData = {
                option_group_uuid: this.optionGroupUuid,
                option_uuid: this.$data.registration_type.uuid,
            }
        }
        try {
            /**
             * @info this is where the next page is determined during login.
             */
            const response = await this.$store.dispatch('auth/login', payload)
            if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`) && window.location.href.indexOf('/group')) {
                Container.get(Notification).error('This email already exists')
                return
            }
            // unpack participant_data, and viewable_reg_pages from response.
            const { participant_data, viewable_reg_pages, next_page: response_next_page } = response.data
            // store status.
            const status = participant_data.status
            // plan next page. If complete to to start of form, otherwise the next page
            const next_page = status === 'Complete' ? viewable_reg_pages[0] : response_next_page.toString()

            if (status === 'Canceled') {
                this.showErrorModal(this.settings.cancellation_verbiage.en, 'Cancelled Registration')
            } else {
                if (payload.data.registrationTypeData) {
                    const registrationTypeData = payload.data.registrationTypeData

                    const dataOption = {
                        option_group_uuid: registrationTypeData.option_group_uuid,
                        option_uuid: registrationTypeData.option_uuid,
                        event_identifier: this.$data.event_identifier,
                        qty: 1, //default to 1 for now
                    }
                    await this.$store.dispatch('options/optionChange', dataOption, { root: true })
                }

                this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier +'/register/' + next_page)
            }
        } catch (error) {
            if (error.response.data.error_code === 'AUTH_FIELD_MISMATCH') {
                Container.get(Notification).error(error.response.data.error_message)
                return
            } else if (error.response.data.error_code === 'EDITING_CLOSED') {
                this.showErrorModal(this.settings.editing_disabled_verbiage.en, 'Editing Disabled')
            } else if (error.response.data.error_code === 'NEED_AUTH_FIELD') {
                if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`) && window.location.href.indexOf('/group')) {
                    Container.get(Notification).error('This email already exists')
                    return
                } else {
                    this.$store.commit('auth/SET_NEED_AUTH_FIELD', error.response.data.field_needed)
                }
            } else if (error.response.data.error_code === 'REGISTRATION_NOT_FOUND') {
                if (payload.participant_uuid) {
                    this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier)
                    Container.get(Notification).error('This registration id does not exist.')
                    return
                }
                await this.handleNoRegistrationError(payload);
            } else {
                Container.get(Notification).error('Please specify a valid key.')
            }
        } finally {
            this.$store.dispatch('common/hideLoader')
        }
    }

    async sendLoginDetails () {
        try {
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                data: {}
            }
            for (const field in this.$data.loginFieldData) {
                payload.data[field] = this.$data.loginFieldData[field]
            }
            await this.$store.dispatch('auth/emailLoginDetails', payload)
            Container.get(Notification).success('Email sent succesfully')
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async sendResetPassword () {
        try {
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                data: {}
            }
            for (const field in this.$data.loginFieldData) {
                payload.data[field] = this.$data.loginFieldData[field]
            }
            await this.$store.dispatch('auth/emailResetPassword', payload)
            Container.get(Notification).success('Email sent successfully.')
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async addToReferralCodeClickCount () {
        try {
            const data = {
                event_identifier: this.$data.event_identifier,
                referral_code: this.$data.referralCode
            }
            await this.$store.dispatch('promote/addToReferralCodeClickCount', data)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

	getValueOfProperty(prop: string) {
		let value = ''
		if (Object.keys(this.design).length > 0){
			Object.keys(this.design).forEach((key: any) => {
                const parts = key.split('_')
				if(parts.shift() === this.template_name) {
					const property = parts.join('_')
					if(property === prop) {
						value = this.design[key]
					}
				}
			})
		}
		return value
	}

    async startMcProSignIn() {
        const resp = await this.$store.dispatch('event/mcProStart', this.event_uuid)
        this.checkURLAndGo(resp.data.redirect_url)
    }

    async startMcTradeSignIn() {
        const resp = await this.$store.dispatch('event/mcTradeStart', this.event_uuid)
        this.checkURLAndGo(resp.data.redirect_url)
    }
    //#endregion
}
